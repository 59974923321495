import { Link } from "gatsby"
import * as React from "react"

import { HeaderLayout } from "../components/headerLayout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <HeaderLayout
    tag="div"
    bgStyle={{ height: "100vh" }}
    wrapperStyle={{ height: "100%", justifyContent: "center" }}
  >
    <p sx={{ fontSize: ["1.5rem", "2rem"], color: "tmcYellow" }}>
      Na téhle stránce bohužel nic není, <br />
      zkuste to na{" "}
      <Link
        to="/"
        sx={{
          color: "white",
          textDecoration: "underline",
          textDecorationThickness: "2px",
          textUnderlinePosition: "from-font",
        }}
      >
        úvodní straně
      </Link>
    </p>
  </HeaderLayout>
)

export default NotFoundPage

export const Head = () => <Seo title="Stránka neexistuje" />
